@import "main";

html,
body {
  font-size: #{$global-base-fontsize + px};
}

a {
  color: $c-primary-brand;
  aus-typography {
    ::ng-deep {
      .typography {
        color: $c-primary-brand !important;
      }
    }
  }
}

.form {
  width: 100%;
  display: block;
  margin: 0 auto;
  &__header {
    display: block;
    text-align: center;
    margin-bottom: 2.813rem;
  }
  &__controls {
    max-width: 20.625rem;
    margin-top: 2.188rem; // 35px
    margin-left: auto;
    margin-right: auto;
    margin-bottom: to-rem(55);
  }
  &__control {
    margin-bottom: to-rem(24);
    &-msg {
      ::ng-deep {
        .typography {
          color: $c-primary-brand;
        }
      }
      &--info {
        ::ng-deep {
          .typography {
            color: $c-gray;
          }
        }
      }
    }
  }
  &__footer {
    text-align: center;
    &__link {
      margin-top: to-rem(10);
      display: inline-block;
      text-decoration: none;
      &:only-child {
        margin-top: 0;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.spinner-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin spinner($absolute: false) {
  &:after {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    border: 5px solid color(primary, 2, brand);
    border-bottom-color: transparent;
    @if $absolute {
      animation: rotation-absolute 1s linear infinite;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes rotation-absolute {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}

.armk-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 64px;
  @include spinner();
}

.alert-max-limit {
  display: block;
  max-width: 43rem;
  margin-left: auto;
  margin-right: auto;
}

.loading {
  pointer-events: none;
  cursor: default;
  position: relative;
  & > * {
    opacity: 0.25;
  }
  @include spinner(true);
}

::ng-deep .flex {
  display: flex;
}

::ng-deep .mr-5 {
  margin-right: 5px;
}

body .indicates-required-field {
  &--asterisk .typography {
    color: color(accent, 02);
  }
  &--text .typography {
    color: color(neutral, 06);
  }
}
