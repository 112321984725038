@use "node_modules/@vestis/ngx-design-system/assets/styles/settings/colors" as
  vestiscolors;
@use "node_modules/@vestis/ngx-design-system/assets/styles/settings/shadows" as
  vestisshadows;

@function shadow($value) {
  @return vestisshadows.shadow($value);
}
@function color($palette, $color, $subColor: null) {
  @return vestiscolors.color($palette, $color, $subColor);
}
@function colorRgbSimple($palette, $color, $subColor: null) {
  @return vestiscolors.colorRgbSimple($palette, $color, $subColor);
}

$global-breakpoints: (
  sm: "screen and (min-width: 425px)",
  md: "screen and (min-width: 768px)",
  lg: "screen and (min-width: 1024px)",
  xl: "screen and (min-width: 1366px)",
);

$global-base-fontsize: 16;

$c-primary-brand: #eb002a;
$c-gray: #505d68;

@mixin at-breakpoint($size, $breakpoints: $global-breakpoints) {
  $breakpoint-found: map-has-key($breakpoints, $size);

  @if ($breakpoint-found == true) {
    $breakpoint: map-get($breakpoints, $size);

    @media #{$breakpoint} {
      @content;
    }
  } @else {
    @warn "Breakpoint size " + #{$size} + " doesn't exist.";
  }
}

@function to-rem($pixels) {
  $remValue: calc($pixels / $global-base-fontsize) + rem;
  @return $remValue;
}
